import { Redirect, Route, Switch } from "wouter"
import { AdminDashboard } from "./pages/admin/AdminDashboard/AdminDashboard"
import { Header } from "./components/header/Header"
import { Login } from "./pages/Login"
import { Tasks } from "./pages/main/dashboard/Tasks"
import { ProjectView } from "./pages/main/projectView/ProjectView"
import { OpenProjectView } from "./pages/main/projectView/OpenProject"
import { ViewTask } from "./pages/main/projectView/task/ViewTask"
import { FeatureProvider } from "./providers/FeatureProvider"
import { SignupComponent } from "./pages/Signup/SignupComponent"
import { useWatcher } from "./lib/state/useWatcher"
import { authState } from "./store/user"
import { UserView } from "./pages/main/user/UserView"

export const App = () => {
  const auth = useWatcher(authState)
  // is not logged in
  if (!auth.isAuth) {
    return (
      <Switch>
        <Route path="/signup/:id" component={SignupComponent} />
        <Route>
          <Login />
        </Route>
      </Switch>
    )
  }

  // is logged in
  return (
    <>
      <FeatureProvider />
      <Header />
      <Switch>
        <Route path="/admin/dashboard" component={AdminDashboard} nest />
        <div
          style={{
            padding: "0 16px",
            maxWidth: "800px",
            margin: "0 auto",
            height: "calc(100% - 54px)",
          }}
        >
          <Route path="/" component={Tasks} />
          <Route path="/me" component={UserView} />
          <Route path="/dashboard" component={Tasks} />
          <Route path="/dashboard/project/:id" component={ProjectView} />
          <Route
            path="/dashboard/project/:projId/:subId"
            component={OpenProjectView}
          />
          <Route
            path="/dashboard/project/:projId/:subId/:taskId"
            component={ViewTask}
          />
        </div>
        <Redirect to="/dashboard" />
        <Route>404</Route>
      </Switch>
    </>
  )
}
