import { Worker } from "../../types"
import { localFetch } from "./localFetch"

export const getSelf = () => {
  return localFetch(`/api/self`)
}

export const getUsers = () => {
  return localFetch(`/api/views/workers`)
}

export const createUser = (
  body: Pick<Worker, "name" | "email" | "role"> &
    Partial<Pick<Worker, "permissions">>
) => {
  return localFetch(`/api/tenants/users`, {
    method: "POST",
    body: JSON.stringify(body),
  })
}

export const deleteUser = (id: number) => {
  return localFetch(`/api/tenants/users/${id}`, { method: "DELETE" })
}

export const updateUser = (
  id: number,
  body: Partial<
    Pick<
      Worker,
      "name" | "username" | "email" | "coeficient" | "permissions" | "role"
    >
  >
) => {
  return localFetch(`/api/tenants/users/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
  })
}

export const finishSignup = (
  body: {
    name: string // min length: 4
    username: string // min length: 3
    password: string // min length: 6
  },
  id: string
) => {
  return localFetch(`/api/signup/${id}`, {
    method: "POST",
    body: JSON.stringify(body),
  })
}
