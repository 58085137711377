import { formatDate } from "date-fns"
import { Task } from "../../../../../types"
import { EditIcon } from "../../../../components/button/IconButton"
import styles from "./view-task.module.css"
import { formatTimeDelta } from "../../../../lib/formatTimeDelta"
import { Button } from "../../../../components/button/Button"
import classNames from "classnames"
import { isBitus } from "../../../../../config/urls"
import { useWatcher } from "../../../../lib/state/useWatcher"
import { userState } from "../../../../store/user"

// status
// TaskStatus_Created         TaskStatus = 0
// TaskStatus_Approved        TaskStatus = 1
// TaskStatus_StatusToStarted TaskStatus = 2
// TaskStatus_StatusToDone    TaskStatus = 3
//

export const HistoryItem = ({
  task,
  onEdit,
}: {
  task: Task
  onEdit: (task: Task) => void
}) => {
  const user = useWatcher(userState)

  if (task.status === 2) {
    return (
      <div key={task.id} className={styles.historyItem}>
        <div className={styles.historyLeftSide}>
          <div>
            <div className={styles.historyName}>{task.user.name}</div>
            <div className={styles.historyDate}>
              {formatDate(new Date(task.date), "dd.MM.yyyy")}
            </div>
          </div>
        </div>
        <div className={classNames(styles.historyCount, styles.historyDanger)}>
          Atzīmēja kā nepabeigtu
        </div>
      </div>
    )
  }

  if (task.status === 3) {
    return (
      <div key={task.id} className={styles.historyItem}>
        <div className={styles.historyLeftSide}>
          <div>
            <div className={styles.historyName}>{task.user.name}</div>
            <div className={styles.historyDate}>
              {formatDate(new Date(task.date), "dd.MM.yyyy")}
            </div>
          </div>
        </div>
        <div className={classNames(styles.historyCount, styles.historySuccess)}>
          Atzīmēja kā pabeigtu
        </div>
      </div>
    )
  }
  return (
    <div key={task.id} className={styles.historyItem}>
      <div className={styles.historyLeftSide}>
        <div>
          <div className={styles.historyName}>{task.user.name}</div>
          <div className={styles.historyDate}>
            {formatDate(new Date(task.date), "dd.MM.yyyy")}
          </div>
        </div>
      </div>
      <div className={styles.historyRightSide}>
        {task.status < 1 && task.user.id === user.id ? (
          <div>
            <Button variant="secondary" onClick={() => onEdit(task)}>
              Labot {EditIcon}
            </Button>
          </div>
        ) : null}
        <div className={styles.historyCount}>
          {isBitus ? (
            <div>{task.unitsUsed ?? 0} gb.</div>
          ) : task.salaryUsed ? (
            <div>{task.salaryUsed / 100}€</div>
          ) : null}
          <div>{formatTimeDelta(task.timeUsed)}</div>
        </div>
      </div>
    </div>
  )
}
